body {
  margin: 0;
  min-height: 100%;
  background-color: #12141d;
  font-family: Cactus, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Cactus, sans-serif;
  font-size: 48px;
  line-height: 1.3em;
  font-weight: 700;
  letter-spacing: -0.05em;
}
h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Arial, sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  letter-spacing: -0.025em;
}
h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Arial, sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: -0.01em;
}
h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Arial, sans-serif;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: -0.01em;
}
.App{
  background-color: #12141d;
  color: white;
}
.header {
  background-color: #12141d;
  width: 100%;
  padding: 10px 0;
  text-align: left;
  margin-bottom: 50px;
}
.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Eudoxussans, sans-serif;
  color: #fff;
  font-size: 28px;
  line-height: 1.35em;
  font-weight: 700;
  letter-spacing: -0.03em;
  text-decoration: none;
}
.w-nav-brand {
  text-decoration: none;
  color: #ffffff;
}
.text-block-2 {
  font-family: 'cactus', sans-serif;
  font-size: 40px;
  font-weight: 400;
}
.body {
  background-color: #12141d;
  width:100%;
  padding: 0;
  margin: 0 auto;
  text-align: center;
}
.left {
  background-color: #12141d;
  width: 100%;
  padding: 0;
}
.right {
  background-color: #12141d;
  width: 44%;
  float:left;
}
.large-heading {
  font-size: 54px;
  line-height: 70px;
}
.bold-text {
  font-weight: 400;
}
.body-display {
  color: #8a94a6;
  line-height: 1.55em;
  width: 70%;
  margin: 0 auto;
}
.body-display.large {
  margin-top: 20px;
  font-family: Inter, sans-serif;
  color: #e4e5e6;
  font-size:20px;
  line-height: 1.6em;
  font-weight: 200;
}
.right-intro{
  margin: 40px auto;
  padding: 10px;
  grid-auto-columns: 1fr;
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  border-style: solid;
  border-width: 1px;
  border-color: #e3e8f3;
  border-radius: 4px;
  width:455px;
}
.connect-button {
  border: 1px solid white;
  border-radius: 10px;
  opacity: 1;
  background-color: #f6851b;
  color: white;
  height: 50px;
  width: 300px;
  margin: 0 auto;
  font-size: 26px;
}
.button.mint-button {
  margin-bottom: 10px;
  border-width: 2px;
  border-radius: 4px;
  background-color: #04AA6D;;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid white;
  height: 50px;
  width: 300px;
  margin: 10px auto;
}
.quantity {
  font-size: 28px;;
}
input[type=number] {
  padding:10px 20px 10px 0;
  border-radius:10px;
  height: 24px;
  font-size:24px;
  width: 50px;
  text-align: right;
}
input[type=text] {
  padding:10px 20px 10px 0;
  border-radius:10px;
  height: 24px;
  font-size:24px;
  width: 400px;
  text-align: right;
}

.admin-button {
  padding:5px 15px;
  background:#ccc;
  border:0 none;
  cursor:pointer;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: 24px;
}
.error {
  border: 1px solid red;
  padding: 10px;
  background-color: white;
  color: red;
  font-size: 26px;
  width: 300px;
  margin: 0 auto;
  border-radius: 10px;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}
.image-2 {
  border-radius: 7px;
}

@font-face {
  font-family: 'Cactus';
  src: url('https://uploads-ssl.webflow.com/60f64cb9db232b3d3161bcb4/60f651bfa15ad327ab51a847_Cactus%20Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@media (max-width: 523px) {
  .right-intro{
    margin: 40px auto;
    padding: 10px;
    grid-auto-columns: 1fr;
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    border-style: solid;
    border-width: 1px;
    border-color: #e3e8f3;
    border-radius: 4px;
    width:90%;
  }
  .image-2 {
    width:994%;
  }
}
